import React, { useState } from "react"
import { navigate, Link } from "gatsby"
import styled, { keyframes } from "styled-components"
import typography from "../utils/typography"

import Layout, { DetailSection } from "../components/layout"
import HeroTitle from "../components/hero/heroTitle"
import Image from "../components/image"
import SEO from "../components/seo"
import DetailTile from "../components/detailTile"
import WaveButton from "../components/buttons/waveButton"
import Reveal from "../components/reveal"
import CheckGuestForm from "../components/forms/checkEmailForm"

const ogg = typography.options.headerFontFamily.join()

const Hero = styled.div`
  display: grid;
  min-height: 100vh;
  grid-column: main / gutter-right;
  align-content: center;
`

const Offset = styled.div`
  display: grid;
  grid-column: 2 / -1; /* start position from DetailSection */
  grid-template-columns: minmax(0, 3fr) 1fr minmax(0, 3fr);

  @media screen and (min-width: 46rem) {
    grid-column: 3 / -1; /* start position from DetailSection */
    grid-template-columns: minmax(0, 2fr) 1fr minmax(0, 2fr);
  }

  @media screen and (min-width: 56rem) {
    grid-column: 4 / -1; /* start position from DetailSection */
    grid-template-columns: minmax(0, 2fr) 1fr minmax(0, 2fr);
  }
`

const Text = styled.div`
  max-width: 17.75rem;
  margin-left: auto;
  font-family: ${ogg};
  font-size: 1.375rem;
  text-transform: lowercase;
  letter-spacing: 0.125rem;

  @media screen and (min-width: 45rem) {
    margin-top: 1rem;
  }

  @media screen and (min-width: 65rem) {
    max-width: 24.5rem;
    margin-top: 1.5rem;
    font-size: 2rem;
  }
`

const StyledText = styled.span`
  font-family: ${ogg};
  font-size: 1.875rem;
  line-height: calc(42 / 36);

  @media screen and (min-width: 37.5rem) {
    font-size: 2.25rem;
  }
`

const Italics = styled.em`
  font-style: italic;
  font-family: Ogg-Italic;
`

const Info = styled.p`
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
  font-family: Inter;
  font-size: 0.875rem;
`

const IndexPage = () => {
  const [showForm, setShowForm] = useState(false)
  const [emailSent, setEmailSent] = useState(false)

  const cancel = () => {
    setShowForm(false)
  }

  const showConfirmation = (email: string) => {
    navigate("/guest-found", { state: { email } })
  }

  return (
    <Layout>
      <SEO title="Wedding" />
      <Hero>
        <HeroTitle></HeroTitle>
        <Reveal delay="2.15s">
          <Text>
            join us for an evening of <Italics>revelry</Italics> and{" "}
            <Italics>celebration</Italics>
          </Text>
        </Reveal>
      </Hero>

      <DetailSection>
        <Offset>
          <DetailTile label="Date">
            <StyledText>
              Sunday,
              <br />
              September 27,
              <br />
              2020
            </StyledText>
          </DetailTile>

          <DetailTile label="Venue">
            <StyledText>
              Factory
              <br />
              Atlanta
              <br />
            </StyledText>
          </DetailTile>

          <DetailTile label="RSVP">
            <Info>Please send us your response by August 1.</Info>
            {showForm ? (
              <CheckGuestForm
                handleCancelForm={() => cancel()}
                handleSuccess={(email: string) => showConfirmation(email)}
              ></CheckGuestForm>
            ) : (
                <WaveButton handleClick={() => { }} disabled={true}>
                  View invitation
                </WaveButton>
              )}
          </DetailTile>
        </Offset>
        <Offset>
          <DetailTile label="">
            <StyledText><span style={{ whiteSpace: 'nowrap' }}>#kwakanalia</span></StyledText>
          </DetailTile>
        </Offset>
      </DetailSection>

      {/* <Section>
      <SEO title="Home" />
      <h1>Hi people</h1>
      <p>Welcome to your new Gatsby site.</p>
      <p>Now go build something great.</p>
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
        <Image />
      </div>
      <Link to="/page-2/">Go to page 2</Link>
    </Section> */}
    </Layout>
  )
}

export default IndexPage
